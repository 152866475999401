import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React, { useEffect, useRef, useState } from "react"
import FingerPrintLeft from "../components/FingerPrint/FingerPrintLeft"
import FingerPrintRight from "../components/FingerPrint/FingerPrintRight"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreContext } from "../store"

const CaseStudies = ({ data, location }) => {
  const { viewPortWidth } = React.useContext(StoreContext)

  const setFooter =
    data.allWpPage.edges[0]?.node.headerAndFooter.pageFooterStyle
  const caseStudiesPage = data.allWpPage.edges[0]?.node

  // Default variable declarations
  let postsRelImgBg = ""
  let postsRelImg = ""

  let featuredRelImgBg = ""
  let featuredRelImg = ""

  let studyLogo = ""

  let mainFeaturedLogo = ""
  let mainFeaturedLogoFinal = ""
  let caseStudiesFooterBlockFieldName

  // Variables for Taxonomy Lists
  const IndustryTags = data.allWpIndustryTag.edges
  const CustomerTags = data.allWpCustomersTag.edges
  const Cstudies = data.allWpCaseStudy.edges

  // Variables for Featured Section

  let mainFeaturedTitle = ""
  let mainFeaturedLongTitle = ""
  let mainFeaturedSlug = ""
  let mainFeaturedStudy = ""
  let mainFeaturedLogoPublic = ""
  let mainFeaturedLogoAlt = ""
  let mainFeaturedFeaturedImgAlt = ""
  let caseStudiesFooterBlock = ""
  let caseStudiesFooterBlockMarkup = ""

  if (
    data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
      .featuredCaseStudyPost !== null
  ) {
    mainFeaturedStudy =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .featuredCaseStudyPost[0]
    mainFeaturedFeaturedImgAlt = mainFeaturedStudy.featuredImage.node.altText
    mainFeaturedLogoPublic =
      mainFeaturedStudy.caseStudyLogo.caseStudyLogo.localFile.publicURL
    mainFeaturedLogoAlt = mainFeaturedStudy.caseStudyLogo.caseStudyLogo.altText
    mainFeaturedTitle = mainFeaturedStudy.title
    mainFeaturedLongTitle =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .featuredCaseStudyTitle
    mainFeaturedSlug = mainFeaturedStudy.slug

    if (
      mainFeaturedStudy.caseStudyLogo.caseStudyLogo.localFile.childImageSharp
    ) {
      mainFeaturedLogo =
        mainFeaturedStudy.caseStudyLogo.caseStudyLogo.localFile.childImageSharp
          .gatsbyImageData
      mainFeaturedLogoFinal = getImage(mainFeaturedLogo)
    }

    if (mainFeaturedStudy.featuredImage.node.localFile.childImageSharp)
      featuredRelImg = getImage(
        mainFeaturedStudy.featuredImage.node.localFile.childImageSharp
          .gatsbyImageData
      )
    featuredRelImgBg = convertToBgImage(featuredRelImg)
  }

  // Featured Footer Item
  if (
    data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
      .caseStudiesFooterContent !== null
  ) {
    caseStudiesFooterBlockFieldName =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .fieldGroupName
    caseStudiesFooterBlock =
      data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
        .caseStudiesFooterContent.content
    caseStudiesFooterBlockMarkup = () => {
      if (caseStudiesFooterBlock) {
        return { __html: `${caseStudiesFooterBlock}` }
      }
    }
  }

  // Toggle States for buttons and dropdowns
  const [isActive, setActive] = useState(true)
  const [isOpen, setOpen] = useState(true)
  const [inLabel, setIndustryLabel] = useState("Industries")
  const [cusLabel, setCustomerLabel] = useState("Customers")

  const industryRef = useRef()
  const customerRef = useRef()

  const handleToggle = () => {
    setActive(!isActive)
    setOpen(false)
  }

  const handleToggle2 = () => {
    setOpen(!isOpen)
    setActive(false)
  }

  useEffect(() => {
    function handler(event) {
      // change starts here
      if (!industryRef.current?.contains(event.target)) {
        setOpen(true)
      }
      if (!customerRef.current?.contains(event.target)) {
        setActive(true)
      }
      // change starts here
    }
    window.addEventListener("click", handler)
    return () => window.removeEventListener("click", handler)
  }, [])

  const temp = `${mainFeaturedSlug}/`

  return (
    <Layout>
      <Seo pageData={caseStudiesPage} location={location} />

      <div className="archive post-type-archive post-type-archive-patterns-case-study wp-embed-responsive">
        <div className="Page">
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <div className="Archive">
              <div className="Wrap">
                <p className="Archive-breadcrumb">
                  <span className="BreadcrumbChild">Case Studies</span>
                </p>
                {data.allWp.edges[0].node.overviewCaseStudies
                  .overviewCaseStudies.featuredCaseStudyPost !== null && (
                  <div className="CaseStudy-featured">
                    <FingerPrintLeft />
                    <FingerPrintRight />
                    <article className="Content patterns-case-study type-patterns-case-study status-publish has-post-thumbnail hentry">
                      <div className="Content-text">
                        <div className="Content-kicker">
                          <span>Featured Customer Story</span>
                        </div>
                        <header className="Content-header">
                          <h1 className="Content-title">
                            {mainFeaturedLongTitle
                              ? mainFeaturedLongTitle
                              : mainFeaturedTitle}
                          </h1>
                        </header>
                        <Link to={temp} target="" className="Content-button">
                          Read More
                        </Link>
                        <div className="Content-image">
                          <div className="Content-image-logo">
                            {mainFeaturedStudy.caseStudyLogo.caseStudyLogo
                              .localFile.extension === "svg" && (
                              <img
                                src={mainFeaturedLogoPublic}
                                alt={mainFeaturedLogoAlt}
                              />
                            )}

                            {mainFeaturedStudy.caseStudyLogo.caseStudyLogo
                              .localFile.extension !== "svg" && (
                              <GatsbyImage
                                image={mainFeaturedLogoFinal}
                                alt={mainFeaturedLogoAlt}
                              />
                            )}
                          </div>
                          <BackgroundImage
                            Tag="div"
                            {...featuredRelImgBg}
                            className="Content-image-thumbnail"
                            alt={mainFeaturedFeaturedImgAlt}
                          ></BackgroundImage>
                        </div>
                      </div>
                    </article>
                  </div>
                )}

                <div className="CaseStudy-filter">
                  <div className="Archive-filters">
                    <div className="Archive-filters-label">FILTER BY</div>
                    <div className="Archive-filters-form">
                      <div
                        className={`Archive-filters-form--list no-hover Industries ${
                          !isActive ? "is-open" : ""
                        }`}
                        onClick={handleToggle}
                        ref={customerRef}
                      >
                        <div className="Archive-filters-label">{inLabel}</div>
                        <div className="Archive-filters-field Select-options">
                          <ul>
                            <li>
                              <a
                                name="archive_filter_patterns-case-study-industry_all"
                                data-filter-type="patterns-case-study-industry"
                                data-filter-value="all"
                                data-filter-label=""
                                href={void 0}
                                onClick={() => setIndustryLabel("Industries")}
                              >
                                All Industries
                              </a>
                            </li>
                            {IndustryTags.map(el => {
                              const inTagsName = el.node.name
                              const inTagsDatabaseId = el.node.databaseId

                              return (
                                <li key={inTagsDatabaseId}>
                                  <a
                                    name={`archive_filter_patterns-case-study-industry_${inTagsDatabaseId}`}
                                    data-filter-type="patterns-case-study-industry"
                                    data-filter-value={inTagsDatabaseId}
                                    data-filter-label={inTagsName}
                                    href={void 0}
                                    onClick={() =>
                                      setIndustryLabel(
                                        inLabel ? inTagsName : ""
                                      )
                                    }
                                  >
                                    {inTagsName}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>

                      <div
                        className={`Archive-filters-form--list no-hover Customers ${
                          !isOpen ? "is-open" : ""
                        }`}
                        onClick={handleToggle2}
                        ref={industryRef}
                      >
                        <div className="Archive-filters-label">{cusLabel}</div>
                        <div className="Archive-filters-field Select-options">
                          <ul>
                            <li>
                              <a
                                name="archive_filter_patterns-case-study-customer_all"
                                data-filter-type="patterns-case-study-customer"
                                data-filter-value="all"
                                data-filter-label=""
                                href={void 0}
                                onClick={() => setCustomerLabel("Customers")}
                              >
                                All Customers
                              </a>
                            </li>
                            {CustomerTags.map(el => {
                              const cusTagsName = el.node.name
                              const cusTagsDatabaseId = el.node.databaseId
                              return (
                                <li key={cusTagsDatabaseId}>
                                  <a
                                    name={`archive_filter_patterns-case-study-customer_${cusTagsDatabaseId}`}
                                    data-filter-type="patterns-case-study-customer"
                                    data-filter-value={cusTagsDatabaseId}
                                    data-filter-label={cusTagsName}
                                    href={void 0}
                                    onClick={() =>
                                      setCustomerLabel(
                                        cusLabel ? cusTagsName : ""
                                      )
                                    }
                                  >
                                    {cusTagsName}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="CaseStudy-heading">Customers</h2>

                <div className="Archive is-CaseStudies">
                  <div className="Archive-grid">
                    {Cstudies.filter(item => {
                      const active = item

                      let current = []
                      active.node.industryTags.nodes.map(inTag => {
                        current = [...current, inTag.name]
                      })

                      active.node.customersTags.nodes.map(cusTag => {
                        current = [...current, cusTag.name]
                      })

                      if (
                        (current.includes(inLabel) &&
                          cusLabel === "Customers") ||
                        (current.includes(cusLabel) &&
                          inLabel === "Industries") ||
                        (inLabel === "Industries" &&
                          cusLabel === "Customers") ||
                        (current.includes(inLabel) &&
                          current.includes(cusLabel))
                      ) {
                        return item
                      }
                    }).map((study, i) => {
                      const studyID = study.node.databaseId
                      const studyTitle = study.node.title
                      const studyExcerpt = study.node.excerpt
                      const excerptMarkup = () => {
                        return { __html: `${studyExcerpt}` }
                      }
                      const studyUrl = study.node.uri
                      const studyTag = study.node.industryTags.nodes[0].name
                      const publicImage =
                        study.node.featuredImage.node.localFile.publicURL

                      if (
                        study.node.caseStudyLogo.caseStudyLogo.localFile
                          .childImageSharp
                      ) {
                        studyLogo =
                          study.node.caseStudyLogo.caseStudyLogo.localFile
                            .childImageSharp.gatsbyImageData
                      }

                      if (
                        study.node.featuredImage.node.localFile.childImageSharp
                      )
                        postsRelImg = getImage(
                          study.node.featuredImage.node.localFile
                            .childImageSharp.gatsbyImageData
                        )
                      postsRelImgBg = convertToBgImage(postsRelImg)
                      const logo = getImage(studyLogo)
                      const studyFeaturedImageAlt =
                        study.node.featuredImage.node.altText

                      return (
                        <div key={`item-${i}`}>
                          <div>
                            <article
                              id={`post-${studyID}`}
                              className={`Content post-${studyID} patterns-case-study type-patterns-case-study status-publish hentry has-post-thumbnail`}
                            >
                              <Link to={studyUrl} rel="" target="">
                                <div className="Content-category">
                                  {studyTag}
                                </div>
                                <div className="Content-image">
                                  <div className="Content-logo">
                                    {study.node.featuredImage.node.localFile
                                      .extension === "svg" && (
                                      <img
                                        src={publicImage}
                                        alt={studyFeaturedImageAlt}
                                      />
                                    )}
                                    {study.node.featuredImage.node.localFile
                                      .extension !== "svg" && (
                                      <GatsbyImage
                                        image={logo}
                                        alt={studyFeaturedImageAlt}
                                      />
                                    )}
                                  </div>
                                  <div className="Content-thumbnail">
                                    <BackgroundImage
                                      Tag="div"
                                      {...postsRelImgBg}
                                      className="Content-thumbnail-image"
                                    ></BackgroundImage>
                                  </div>
                                </div>
                                <h3 className="ContentTitle">{studyTitle}</h3>
                                <div className="ContentExcerpt">
                                  <span
                                    dangerouslySetInnerHTML={excerptMarkup()}
                                  />
                                </div>

                                <div className="Content-footer">Read More</div>
                              </Link>
                            </article>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data.allWp.edges[0].node.overviewCaseStudies.overviewCaseStudies
            .caseStudiesFooterContent !== null && (
            <span
              className={`${caseStudiesFooterBlockFieldName}-footer`}
              dangerouslySetInnerHTML={caseStudiesFooterBlockMarkup()}
            />
          )}

          {setFooter === null && <Footer />}

          {setFooter === "default" && <Footer />}

          {setFooter === "landing-page" && <FooterLandingPage />}

          {setFooter === "get-started" && <FooterMinimal />}
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudies

export const query = graphql`
  {
    allWpIndustryTag {
      edges {
        node {
          name
          slug
          id
          databaseId
        }
      }
    }
    allWpCustomersTag {
      edges {
        node {
          name
          databaseId
          slug
          id
        }
      }
    }
    allWpCaseStudy {
      edges {
        node {
          databaseId
          title
          uri
          slug
          featuredImage {
            node {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
                absolutePath
              }
              altText
            }
          }
          industryTags {
            nodes {
              name
            }
          }
          customersTags {
            nodes {
              name
            }
          }
          excerpt
          caseStudyLogo {
            caseStudyLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: AUTO
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpPage(filter: { databaseId: { eq: 792 } }) {
      edges {
        node {
          id
          title
          headerAndFooter {
            pageFooterStyle
            pageHeaderStyle
          }
          databaseId
          seo {
            title
            metaDesc
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphPublishedTime
            opengraphModifiedTime
            opengraphSiteName
            opengraphType
            readingTime
          }
          richSnippetSchemaJson
        }
      }
    }
    allWp {
      edges {
        node {
          overviewCaseStudies {
            overviewCaseStudies {
              fieldGroupName
              featuredCaseStudyTitle
              featuredCaseStudyPost {
                ... on WpCaseStudy {
                  id
                  caseStudyLogo {
                    caseStudyLogo {
                      id
                      localFile {
                        extension
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                        publicURL
                        extension
                      }
                      altText
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        extension
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                      }
                    }
                  }
                  slug
                  uri
                  title
                }
              }
              caseStudiesFooterContent {
                ... on WpPage {
                  id
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`
